<template>
    <request :request="analysisRequest" @on-success="onAnalysisRequestSuccess">
        <div v-if="analysis">
            <v-row>
                <v-col cols=12>
                    <h3>Chat analysis page home for File  {{ analysis.record.name }}</h3>
                    <small><b>Created at</b> {{analysis.record.created_at}}</small>
                    <br>
                    <small><b>UID</b> {{analysis.record.uid}}</small>
                </v-col>
                <v-col>
                    <metric label="COUNT MESSAGES" :content="analysis.count" kind="text"/>
                </v-col>
                <v-col>
                    <metric label="TOP 5 TOPICS" :content="analysis.topics" />
                </v-col>
                <v-col>
                    <metric label="SENTIMENT SCORE" :content="analysis.sentiment.mean" unit="%" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-tabs
                        v-model="tab"
                        background-color="primary"
                        dark
                    >
                        <v-tab :key="sentiment">
                            Sentiment Analysis
                        </v-tab>

                        <v-tab :key="sentiment">
                            Ask a Question
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" class="transparent-background">
                        <v-tab-item class="transparent-background">
                            <sentiment-report :analysis-model="analysisModel" />
                        </v-tab-item>
                        <v-tab-item class="transparent-background">
                            <v-row>
                                <v-col cols=12>
                                    <query />
                                </v-col>
                                <v-col cols=12>
                                    <v-data-table
                                        v-if="analysis.table"
                                        :headers="analysis.table.headers"
                                        :items="analysis.table.rows"
                                        :items-per-page="25"
                                        class="elevation-1"
                                    />
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>

        </div>
    </request>
</template>
<script>
    import Metric from '@/components/Metric.vue';
    import ChatAnalysisApi from '../webapi';
    import Query from '../components/Query.vue';
    import SentimentReport from '../components/SentimentReport.vue';
    import AnalysisSerializer from '../models/analysis';

    export default {
        name: 'chat-analysis-page-analysis',
        components: {
            Metric,
            SentimentReport,
            Query,
        },
        props: {
            fileId: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            tab: undefined,
            analysis: undefined,
            analysisModel: undefined,
        }),
        computed: {
            analysisRequest() {
                return () => ChatAnalysisApi.getAnalysis(this.fileId);
            },
        },
        methods: {
            onAnalysisRequestSuccess(data) {
                this.analysis = data;
                this.analysisModel = new AnalysisSerializer(data);
            },
        },
    };
</script>

<style scoped>
    .transparent-background {
        background-color: transparent;
    }
</style>
