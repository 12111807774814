<template>
    <v-card>
        <v-card-title>Sentiment Trend</v-card-title>
        <v-card-text>
            <v-chart :options="chart" />
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'SentimentChart',
        props: {
            analysisModel: {
                type: Object,
                required: true,
            },
        },
        computed: {
            data() {
                return this.analysisModel.getMonthlySentiment();
            },
            chart() {
                const options = {
                    title: {
                        text: 'Sentiment over time',
                    },
                    responsive: true,
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            animation: false,
                            label: {
                                backgroundColor: '#ccc',
                                borderColor: '#aaa',
                                borderWidth: 1,
                                shadowBlur: 0,
                                shadowOffsetX: 0,
                                shadowOffsetY: 0,
                                color: '#222',
                            },
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: this.data.month,
                        splitLine: {
                            show: false,
                        },
                        boundaryGap: false,
                    },
                    yAxis: {
                        axisLabel: {
                            formatter: (val) => val,
                        },
                    },
                    series: [{
                        name: 'Min',
                        type: 'line',
                        data: this.data.min,
                        lineStyle: {
                            opacity: 0,
                        },
                        stack: 'confidence-band',
                        symbol: 'none',
                    }, {
                        name: 'Max',
                        type: 'line',
                        data: this.data.max,
                        lineStyle: {
                            opacity: 0,
                        },
                        areaStyle: {
                            color: '#ccc',
                        },
                        stack: 'confidence-band',
                        symbol: 'none',
                    }, {
                        name: 'Mean',
                        type: 'line',
                        data: this.data.mean,
                        hoverAnimation: false,
                        symbolSize: 6,
                        itemStyle: {
                            color: '#c23531',
                        },
                        showSymbol: false,
                    }],
                };
                return options;
            },
        },
    };
</script>
