<template>
    <v-row>
        <v-col cols=6>
            <sentiment-chart v-if="analysisModel" :analysis-model="analysisModel"/>
        </v-col>
        <v-col cols=6>
            <text-list title="Top 5 negative messages" :items="topNegativeMessages" />
        </v-col>
    </v-row>
</template>

<script>
    import SentimentChart from './SentimentChart.vue';
    import TextList from './TextList.vue';

    export default {
        name: 'SentimentReport',
        components: {
            SentimentChart,
            TextList,
        },
        props: {
            analysisModel: {
                type: Object,
                required: true,
            },
        },
        computed: {
            topNegativeMessages() {
                return this.analysisModel.getTopNegativeMessages();
            },
        },
    };
</script>
