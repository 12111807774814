<template>
    <v-row>
        <v-col
            cols="12"
            sm="6"
        >
            <v-date-picker
                v-model="dates"
                range
            />
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
            <v-text-field
                v-model="dateRangeText"
                label="Date range"
                prepend-icon="mdi-calendar"
                outlined
                readonly
            />
            model: {{ dates }}
        </v-col>
    </v-row>
</template>

<script>
    const modes = {
        current: {
            day: 'day',
            week: 'week',
            month: 'month',
            year: 'year',
        },
        on: {},
        between: {},
    };

    export default {
        name: 'QueryDatepicker',
        data: () => ({
            modes,
            mode: undefined,
            dates: undefined,
            dateRangeText: undefined,
        }),
    };
</script>
