<template>
    <v-card
        class="mx-auto"
    >
        <v-card-title>{{  title }}</v-card-title>
        <v-list three-line>
            <template v-for="item in items">
                <v-list-item
                    :key="item.title"
                >
                    <v-list-item-content class="text-left">
                        <v-list-item-subtitle v-html="item.title" />
                        <v-list-item-title v-html="item.content" />
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-card>
</template>

<script>
    export default {
        name: 'TextList',
        props: {
            items: {
                type: Array,
                required: true,
            },
            title: {
                type: String,
                default: '',
            },
        },
    };
</script>
