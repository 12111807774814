<template>
    <v-row>
        <v-col>
            <h2>{{ label }}</h2>
        </v-col>
        <v-col>
            <v-select v-model="condition" :items="conditions" item-text="name" item-value="key" outlined dense/>
        </v-col>
        <v-col cols=12>
            <v-text-field outlined v-model="conditionValue" />
        </v-col>
        <v-col cols=12>
            <v-btn color="primary" @onclick="emitFilter">Add filter</v-btn>
        </v-col>
    </v-row>
</template>

<script>
    const conditions = [
        { name: 'Is', key: 'EQUALS' },
        { name: 'Is not', key: 'NOT_EQUALS' },
        { name: 'Contains', key: 'CONTAINS' },
        { name: 'Not contains', key: 'NOT_CONTAINS' },
        { name: 'Is empty', key: 'EMPTY' },
        { name: 'Is not empty', key: 'NOT_EMPTY' },
    ];

    export default {
        name: 'QueryFilter',
        props: {
            label: {
                type: String,
                required: true,
            },
            field: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            conditions,
            condition: undefined,
            conditionValue: undefined,
        }),
        methods: {
            emitFilter() {
                const filter = {
                    target: this.field,
                    condition: this.condition,
                    value: this.confitionValue,
                };
                this.$emit('filter-add', filter);
            },
        },
    };
</script>
