import du from '@/utils/dateutils';

type Data = Record<string, any>;

const TIMESTAMP = 'TIMESTAMP';
const CONTENT = 'MESSAGE';

export default class AnalysisSerializer {
    data: Data;

    constructor(data: Data) {
        this.data = data;
    }

    public getTopNegativeMessages() : Array<Record<string, string>> {
        const messages = this.data.sentiment.negative.map((item: Record<string, any>) => (
            { title: du.datetime(item[TIMESTAMP]), content: item[CONTENT] }
        ));
        return messages;
    }

    public getMonthlySentiment() : Record<string, any> {
        return this.data.sentiment.monthly;
    }
}
