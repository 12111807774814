<template>
    <v-card
        class="mx-auto"
        outlined
        elevation=2
        color="white"
    >
        <v-list-item three-line>
            <v-list-item-content>
                <div class="overline mb-4 text-center">
                    {{ label }}
                </div>
                <v-list-item-title :class="['headline', 'mb-1', 'text-center', `${color}--text`]">
                    {{ content }} {{ unit }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script>
    const NUMBER = 'number';
    // const TEXT = 'text';

    export default {
        name: 'metric',
        props: {
            label: {
                type: String,
                required: true,
            },
            content: {
                type: [String, Boolean, Number],
                required: true,
            },
            unit: {
                type: String,
                default: '',
            },
            kind: {
                type: String,
                default: NUMBER,
            },
        },
        computed: {
            color() {
                if (this.kind === NUMBER) {
                    if (this.content > 0) {
                        return 'success';
                    }
                    if (this.content < 0) {
                        return 'error';
                    }
                }
                return 'black';
            },
        },
    };
</script>
