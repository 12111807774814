<template>
    <v-card>
        <v-card-title>Ask a question</v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-card
                        color="blue lighten-5"
                        flat
                        hide-details="auto"
                    >
                        <v-card-text>
                            <query-timestamp label="Timestamp" field="message" />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card
                        color="yellow lighten-5"
                        flat
                        hide-details="auto"
                    >
                        <v-card-text>
                            <query-filter label="Messages" field="message" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import QueryFilter from './QueryFilter.vue';
    import QueryTimestamp from './QueryTimestamp.vue';

    export default {
        name: 'Query',
        components: {
            QueryFilter,
            QueryTimestamp,
        },
        data: () => ({
        }),
    };
</script>
